
import { defineComponent, onMounted, onUpdated, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { config, asideTheme } from '@/core/helpers/config';
import i18nProvider from '@/core/services/i18nProvider';
import { Notify, Apollo, Axios } from '@/core/services';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { ToggleComponent } from '@/assets/ts/components/_ToggleComponent';
// import KTMenu from "@/layout/aside/Menu.vue";
import { generateAvatar } from '@/core/helpers/generateAvatar';
import AsideMenu from './AsideMenu.vue';
import { useRouter } from 'vue-router';
import InnerLoader from '../../components/InnerLoader.vue';
import { Actions } from '@/store/enums/StoreEnums';

export default defineComponent({
    name: 'KTAside',
    components: {
        AsideMenu,
        InnerLoader
    },
    props: {
        lightLogo: String,
        darkLogo: String,
        asideOpened: Boolean
    },
    setup() {
        const { t } = useI18n();
        const i18n = useI18n();
        const router = useRouter();
        const store = useStore();
        const loader = ref(false);
        const locales: any = ref({});
        const languageArea = ref(false);
        const hideCollapseableArea = ref(false);

        const languageHandler = () => {
            languageArea.value = !languageArea.value;
        };

        i18n.locale.value = localStorage.getItem('lang') ? (localStorage.getItem('lang') as string) : 'en';

        const locales_data = Apollo.readQuery({
            query: GET_LOCALES
        });

        for (const key of locales_data.locales) {
            if (key.locale == 'da' || key.locale == 'en') {
                locales.value[key.locale] = {
                    id: key.id,
                    locale: key.locale,
                    name: key.name,
                    flag: require(`@/assets/media/flags/${key.locale}.svg`)
                };
            }
        }
        const companyLogo = name => {
            return generateAvatar(name);
        };

		const me = computed(() => {
			return store.getters.getUser;
		});
		const hideOnClickOutsideHandler = () => {
			hideCollapseableArea.value = true;
		};
		const trueCollapseableArea = () => {
			hideCollapseableArea.value = false;
		};
		// Logout user
		const signOut = async () => {
			try {
        await store.dispatch("logout");
			} catch (e) {
        Notify.error(e);
        localStorage.removeItem("token");
        localStorage.removeItem('code');
        localStorage.removeItem('state');
        window.location.href = `${process.env.VUE_APP_GEP_AUTH}/sso-logout?continue=${process.env.VUE_APP_BASE_URL}`
			}
		};
		// Set user default language
		const setLang = async (lang: string, id: number) => {
			const formData = {
				locale: lang,
				user_id: store.getters.getUser.id,
			};
			loader.value = true;
			try {
				await Axios.post(`/locales/set-locale`, formData);
				i18nProvider.SetLocaleMessage(lang);
				loader.value = false;
			} catch (e) {
				loader.value = false;
				Notify.error(e);
			}

            languageArea.value = !languageArea.value;
        };

        const currentLanguage = (lang: string) => {
            return i18n.fallbackLocale.value === lang;
        };

        const currentLangugeLocale = computed(() => {
            const locale: any = i18n.fallbackLocale.value;
            return locales.value[locale];
        });

        const setThemeMode = () => {
            localStorage.getItem('admin-theme-mode') == 'dark'
                ? (localStorage.setItem('admin-theme-mode', 'light'), (config.value.aside.theme = 'light'))
                : (localStorage.setItem('admin-theme-mode', 'dark'), (config.value.aside.theme = 'dark'));
        };

        const handleClickOutside = event => {
            if (!event.target.closest('.outside-click')) {
                languageArea.value = false;
            }
        };

        onMounted(() => {
            DrawerComponent.reinitialization();
            ToggleComponent.reinitialization();
            document.addEventListener('click', handleClickOutside);
        });

        onUpdated(() => {
            ToggleComponent.bootstrap();
        });

        return {
            asideTheme,
            t,
            companyLogo,
            setLang,
            locales,
            currentLanguage,
            languageHandler,
            languageArea,
            me,
            signOut,
            currentLangugeLocale,
            config,
            setThemeMode,
            loader,
            hideOnClickOutsideHandler,
            hideCollapseableArea,
            trueCollapseableArea,
            handleClickOutside
        };
    }
});
