
import { defineComponent, inject, ref } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
	name: "InnerHeader",
	props: {
		margin: Number,
		pageHeader: Object,
	},
	setup() {
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const pageAction = (actionButton) => {
			emitter.emit(`${actionButton?.pageAction.action}`, actionButton?.pageAction.params);
		};
		const getTranslation = (keys) => {
			if (keys != undefined) {
				const splitKeys = keys.split(" ");
				if (splitKeys.length > 0) {
					splitKeys.forEach((item, index, array) => {
						array[index] = i18n.t(item);
					});
					return splitKeys.join(" ");
				}
				return i18n.t(keys);
			}
		};
		return {
			pageAction,
			getTranslation,
		};
	},
});
