
import { defineComponent } from "vue";

export default defineComponent({
  name: "mobile-page-title",
  components: {},
  props: {
    breadcrumbs: Array,
    title: String
  }
});
