
import { defineComponent, computed, onMounted, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { asideTheme } from "@/core/helpers/config";
import KTAside from "@/layout/aside/Aside.vue";
// import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
// import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTMobilePageTitle from "@/layout/toolbar/MobilePageTitle.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
// import KTLoader from "@/components/Loader.vue";
import KTDrawerMessenger from "@/layout/extras/DrawerMessenger.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent, DrawerComponent } from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import { toolbarDisplay, loaderEnabled, contentWidthFluid, loaderLogo, asideEnabled, subheaderDisplay } from "@/core/helpers/config";
import InnerHeader from "@/components/InnerHeader/InnerHeader.vue";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "Layout",
	components: {
		KTAside,
		// KTHeader,
		KTFooter,
		// KTToolbar,
		KTScrollTop,
		KTUserMenu,
		KTDrawerMessenger,
		// KTLoader,
		KTMobilePageTitle,
		InnerHeader,
		InnerLoader,
	},
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		const loader = ref({});

		const asideOpened = ref(false);

		// show page loading
		// store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

		// initialize html element classes
		HtmlClass.init();

		const pageTitle = computed(() => {
			return store.getters.pageTitle;
		});

		const breadcrumbs = computed(() => {
			return store.getters.pageBreadcrumbPath;
		});

		const actionButton = computed(() => {
			return store.getters.getActionButtion;
		});

		const pageHeader = computed(() => {
			return store.getters.getPageHeader;
		});

		const asideHandler = () => {
			asideOpened.value = !asideOpened.value;
		};

		onMounted(() => {
			loader.value = true;
			//check if current user is authenticated
			if (!localStorage.getItem("token")) {
				router.push({ name: "login" });
			}

			// Simulate the delay page loading
			setTimeout(() => {
				loader.value = false;
				// Remove page loader after some time
				store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
			}, 1000);
		});

		watch(
			() => route.path,
			() => {
				MenuComponent.hideDropdowns(undefined);

				DrawerComponent.hideAll();

				// check if current user is authenticated
				if (!localStorage.getItem("token")) {
					router.push({ name: "login" });
				}

				removeModalBackdrop();
			}
		);

		return {
			toolbarDisplay,
			loaderEnabled,
			contentWidthFluid,
			loaderLogo,
			asideEnabled,
			subheaderDisplay,
			pageTitle,
			breadcrumbs,
			actionButton,
			asideTheme,
			pageHeader,
			loader,
			asideHandler,
			asideOpened,
		};
	},
});
