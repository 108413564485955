import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";


/**
 * Set current page breadcrumbs
 * @params {object} data
 */
export const setPageHeader = (data: object): void => {
    store.dispatch(Actions.SET_PAGE_HEADER_ACTION,data);
};

export const setPageHistory = (data: object): void => {
    store.dispatch(Actions.SET_PAGE_HISTORY_ACTION,data);
};

export const setTheme = (data: string): void => {
    alert()
    store.dispatch(Actions.SET_THEME,data);
};